var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audit-log" },
    [
      _c(
        "div",
        { staticClass: "header d-flex align-self-center mb-3" },
        [
          _c("h1", { staticClass: "p-0 mb-0 align-self-center mr-3" }, [
            _vm._v("Audit Log"),
          ]),
          _vm._v(" "),
          _c("team-selector", {
            attrs: { loading: _vm.loadingTeams, teams: _vm.teams },
            model: {
              value: _vm.activeTeam,
              callback: function ($$v) {
                _vm.activeTeam = $$v
              },
              expression: "activeTeam",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("audit-log-card-list", {
        attrs: {
          team: _vm.activeTeam,
          loading: _vm.loadingAuditLogFilters || _vm.loadingTeamUsers,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }