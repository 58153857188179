<template>
  <div class="audit-log-card">
    <b-card
      body-class="d-flex px-4 py-3 card-body"
      v-on="!auditLogData.claimResult ? { click: viewDetails } : {}"
      :class="{ 'audit-log-card-expand': !auditLogData.claimResult }"
    >
      <div class="w-4 align-self-center text-center claim-timestamp">
        <p class="m-0 font-bold">{{ claimSubmitTimestampDate }}</p>
        <p class="m-0">{{ claimSubmitTimestampTime }}</p>
      </div>
      <div class="w-8 align-self-center text-center">
        <b-avatar
          :src="userAvatar"
          :text="getNameInitials(userName)"
          size="30"
          variant="light"
        ></b-avatar>
        <span class="ml-2">{{ userName }}</span>
      </div>
      <div class="w-6 align-self-center text-center">
        <b-badge variant="outline-info">{{ weekName }}</b-badge>
      </div>
      <div class="w-6 align-self-center text-center">
        <b-badge variant="outline-new">{{ dayName }}</b-badge>
      </div>
      <div class="w-6 align-self-center text-center">
        <b-badge pill :variant="resultVariant">{{ result }}</b-badge>
      </div>
      <div
        v-for="(c, index) in claim"
        :key="`answer-${index}`"
        class="w-8 align-self-center text-center mx-auto"
      >
        <div v-if="c.result !== null">
          <p :class="{ 'text-danger font-semi-bold': !c.result }" class="m-0">
            <span v-if="!isNaN(Number(c.input))">{{ Number(c.input) | formatPayment }}</span>
            <span v-else>{{ c.input }}</span>
            <i v-if="!c.result" class="fa fa-times-circle text-danger ml-1"></i>
          </p>
          <p
            v-if="detailsViewed && c.result === false && c.original"
            class="mt-1 m-0 animate__animated animate__fadeIn"
          >
            <span v-if="!isNaN(c.original)">{{ c.original | formatPayment }}</span>
            <span v-else>{{ c.original }}</span>
            <i class="fa fa-check-circle text-success ml-1"></i>
          </p>
        </div>
      </div>
      <div class="w-5 align-self-center text-center action">
        <i
          v-if="auditLogData.claimResult"
          class="fa fa-caret-down align-self-center text-light"
        ></i>
        <i
          v-else
          :key="`view-details-${detailsViewed}`"
          @click.stop="viewDetails"
          class="fa view-details align-self-center animate__animated animate__flipInX"
          :class="detailsViewed ? 'fa-caret-up text-info' : 'fa-caret-down text-muted'"
        ></i>
      </div>
    </b-card>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
import { formatPayment } from '@/constants/config'
export default {
  props: {
    auditLog: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detailsViewed: false
    }
  },
  mixins: [helperMixin],
  filters: {
    formatPayment(value) {
      return formatPayment(value)
    }
  },
  methods: {
    viewDetails() {
      let self = this
      self.detailsViewed = !self.detailsViewed
    }
  },
  computed: {
    auditLogData() {
      return this.auditLog
    },
    claimSubmitTimestamp() {
      let self = this
      return self.auditLogData.claimSubmitTimestamp
    },
    claimSubmitTimestampDate() {
      let self = this
      return self.claimSubmitTimestamp.split(' ')[0]
    },
    claimSubmitTimestampTime() {
      let self = this
      return self.claimSubmitTimestamp.split(' ')[1]
    },
    userAvatar() {
      let self = this
      return self.auditLogData.userAvatar
    },
    userName() {
      let self = this
      return `${self.auditLogData.userFirstName} ${self.auditLogData.userLastName}`
    },
    weekName() {
      let self = this
      return self.auditLogData.weekName
    },
    dayName() {
      let self = this
      return self.auditLogData.dayName
    },
    result() {
      let self = this
      return self.auditLogData.claimResult ? 'success' : 'failed'
    },
    resultVariant() {
      let self = this
      return self.auditLogData.claimResult ? 'success' : 'danger'
    },
    claim() {
      let self = this
      return self.auditLogData.claim
    }
  }
}
</script>
