<template>
  <div class="audit-log-card-list">
    <div v-if="!loading" class="w-100 d-flex flex-column flex-lg-row px-4 py-2">
      <p class="mb-0 w-4 w-sm-100 align-self-center font-bold text-center"></p>
      <p class="mb-0 w-8 w-sm-100 align-self-center font-bold text-center">
        <multi-user-selection-filter
          label="Team Member"
          :options="userOptions"
          v-model="selectedUsers"
          optionImage="avatar"
        ></multi-user-selection-filter>
      </p>
      <p class="mb-0 w-6 w-sm-100 align-self-center font-bold text-center">
        <multi-selection-filter
          label="Week"
          :options="weekOptions"
          v-model="selectedWeeks"
        ></multi-selection-filter>
      </p>
      <p class="mb-0 w-6 w-sm-100 align-self-center font-bold text-center">
        <multi-selection-filter
          label="Day"
          :options="dayOptions"
          v-model="selectedDays"
        ></multi-selection-filter>
      </p>
      <p class="mb-0 w-6 w-sm-100 align-self-center font-bold text-center">
        <multi-selection-filter
          label="Result"
          :options="claimResultOptions"
          v-model="selectedClaimResults"
        ></multi-selection-filter>
      </p>
      <div
        v-for="(field, index) in auditLogFilters.fields"
        :key="`field-${index}`"
        class="mb-0 w-8 w-sm-100 align-self-center font-bold text-center mx-auto"
      >
        <multi-selection-filter
          v-if="field.name.toLowerCase() === 'policy type'"
          label="Policy Type"
          :options="policyTypeOptions"
          v-model="selectedPolicies"
          class="mx-auto"
        ></multi-selection-filter>
        <p
          v-else
          class="mb-0 font-bold claim-field mx-auto"
          v-tooltip="{
            content: field.name,
            placement: 'top-center',
            classes: ['light'],
            delay: {
              show: 500,
              hide: 300
            },
            offset: '5'
          }"
        >
          {{ field.name }}
        </p>
      </div>
      <p class="mb-0 w-5 w-sm-100 align-self-center text-center">
        <b-button
          size="xs"
          variant="danger"
          :disabled="disabledResetFilter"
          @click="resetFilters"
          class="btn-shadow"
        >
          Reset
        </b-button>
      </p>
    </div>
    <div v-if="loading && auditLogData && auditLogData.length === 0" class="d-flex mt-4">
      <running-loader loading loadingText="Loading data..." class="mx-auto"></running-loader>
    </div>
    <div v-else>
      <transition-group
        name="card"
        tag="div"
        enter-active-class="animate__animated animate__fadeInDown"
      >
        <audit-log-card
          v-for="(auditLog, index) in displayedAuditLog"
          :key="auditLog.claimId"
          class="mb-2"
          :auditLog="auditLog"
        ></audit-log-card>
      </transition-group>
      <div v-if="displayedAuditLog && displayedAuditLog.length > 0" class="d-flex mt-4">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          class="mx-auto"
        ></b-pagination>
      </div>
      <div v-else class="d-flex">
        <speech-bubble
          :show="!loadingAuditLogData && displayedAuditLog.length === 0"
          class="mx-auto mt-5"
        >
          <template #content>No audit log found</template>
        </speech-bubble>
        <speech-bubble :show="loadingAuditLogData" class="mx-auto mt-5">
          <template #content>Loading audit log...</template>
        </speech-bubble>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AuditLogCard from '@/components/Dashboard/AuditLogCard.vue'
import MultiSelectionFilter from '@/components/Selections/MultiSelectionFilter'
import MultiUserSelectionFilter from '@/components/Selections/MultiUserSelectionFilter'
import SpeechBubble from '@/components/Notification/SpeechBubble.vue'
import { policyTypes, claimResults } from '@/constants/config'
export default {
  props: {
    team: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AuditLogCard,
    MultiSelectionFilter,
    MultiUserSelectionFilter,
    SpeechBubble
  },
  data() {
    return {
      loadingAuditLogData: true,
      auditLogData: [],
      latestClaimId: 0,
      perPage: 8,
      currentPage: 1,
      selectedWeeks: [],
      selectedDays: [],
      selectedClaimResults: [],
      selectedPolicies: [],
      selectedUsers: []
    }
  },
  methods: {
    ...mapActions(['fetchAuditLog', 'fetchAllUsers']),
    async getAuditLog(claimId) {
      let self = this
      self.loadingAuditLogData = true
      let payload = {
        teamId: self.team.id,
        lastClaimId: claimId
      }
      await self.fetchAuditLog(payload)
      self.auditLogData = self.auditLog
      await self.getLatestClaimId()
      self.loadingAuditLogData = false
    },
    async getLatestClaimId() {
      let self = this
      const ids = self.auditLogData.map((log) => {
        return log.claimId
      })
      self.latestClaimId = Math.max(...ids)
    },
    async initiateAuditLogListener() {
      let self = this
      if (_.isEmpty(self.team) || self.team.id === null) {
        return
      }

      let pusher = window.Pusher
      await pusher.subscribe(`new_submitted_team.${self.team.id}`)
      await pusher.bind('new_submitted_team', async (e) => {
        if (self.latestClaimId !== 0) {
          self.getAuditLog(self.latestClaimId)
        }
      })
    },
    paginate(auditLogData) {
      let self = this
      let page = self.currentPage
      let perPage = self.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return auditLogData.slice(from, to)
    },
    filterWeeks(auditLog) {
      let self = this
      let weeks = self.selectedWeeks.length > 0 ? self.selectedWeeks : self.weekOptions
      return auditLog.filter((log) => weeks.includes(log.weekName))
    },
    filterDays(auditLog) {
      let self = this
      let days = self.selectedDays.length > 0 ? self.selectedDays : self.dayOptions
      return auditLog.filter((log) => days.includes(log.dayName))
    },
    filterClaimResults(auditLog) {
      let self = this
      let results =
        self.selectedClaimResults.length > 0 ? self.selectedClaimResults : self.claimResultOptions
      return auditLog.filter((log) => {
        let claimResultName = claimResults.find((r) => r.result === log.claimResult).name
        return results.includes(claimResultName)
      })
    },
    filterPolicies(auditLog) {
      let self = this
      let policies =
        self.selectedPolicies.length > 0 ? self.selectedPolicies : self.policyTypeOptions
      return auditLog.filter((log) => policies.includes(log.claim[3].input))
    },
    filterUsers(auditLog) {
      let self = this
      let users =
        self.selectedUsers.length > 0 ? self.selectedUsers : self.userOptions.map((user) => user.id)
      return auditLog.filter((log) => users.includes(log.userId))
    },
    resetFilters() {
      let self = this
      self.selectedWeeks = []
      self.selectedDays = []
      self.selectedPolicies = []
      self.selectedUsers = []
    },
    async resetAuditLog() {
      let self = this
      self.auditLogData = []
      self.latestClaimId = 0
    },
    async leaveCurrentChannel(team) {
      let self = this
      if (team) {
        let pusher = window.Pusher
        await pusher.unsubscribe(`new_submitted_team.${team.id}`)
        await self.resetAuditLog()
      }
    },
    async leaveChannels() {
      let self = this
      let pusher = window.Pusher
      await pusher.unsubscribe(`new_submitted_team.${this.team.id}`)
      await self.resetAuditLog()
    }
  },
  computed: {
    ...mapGetters(['auditLog', 'auditLogFilters', 'allUsers']),
    orderedAuditLog() {
      return _.orderBy(this.auditLogData, ['claimId'], ['desc'])
    },
    rows() {
      return this.filteredAuditLog.length
    },
    filteredAuditLog() {
      let self = this
      let auditLog = self.filterWeeks(
        self.filterDays(
          self.filterClaimResults(self.filterPolicies(self.filterUsers(self.orderedAuditLog)))
        )
      )
      self.currentPage = 1
      return auditLog
    },
    displayedAuditLog() {
      let self = this
      return self.paginate(self.filteredAuditLog)
    },
    weekOptions() {
      return this.auditLogFilters.weeks.map((filter) => filter.text)
    },
    dayOptions() {
      return this.auditLogFilters.days.map((filter) => filter.text)
    },
    claimResultOptions() {
      return claimResults.map((cr) => cr.name)
    },
    policyTypeOptions() {
      return policyTypes.map((pt) => pt.name)
    },
    userOptions() {
      return this.allUsers
        .filter(
          (user) => user.team_id === this.team.id && user.role.toLowerCase() !== 'team leader'
        )
        .map((user) => {
          return {
            id: user.id,
            name: user.full_name,
            avatar: user.avatar
          }
        })
    },
    disabledResetFilter() {
      let self = this
      return (
        self.selectedWeeks.length === 0 &&
        self.selectedDays.length === 0 &&
        self.selectedPolicies.length === 0 &&
        self.selectedUsers.length === 0
      )
    }
  },
  created() {
    this.initiateAuditLogListener()
  },
  watch: {
    loading(newValue) {
      let self = this
      if (!newValue) {
        self.getAuditLog(self.latestClaimId)
      }
    },
    team(newTeam, oldTeam) {
      // leave old team channel
      let self = this
      if (oldTeam && oldTeam.id) {
        self.leaveCurrentChannel(oldTeam)
      }

      if (newTeam) self.initiateAuditLogListener()
    }
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}
</script>

<style lang="scss" scoped>
.claim-field {
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
