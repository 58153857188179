var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "multi-user-selection-filter",
      attrs: {
        variant: "none",
        "toggle-class": "options-toggle d-flex align-self-center p-0",
        "menu-class": "options-menu a-shadow-sm",
        "no-caret": "",
      },
    },
    [
      _c("template", { slot: "button-content" }, [
        _c("span", { staticClass: "align-self-center" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _vm._v(" "),
        _vm.selectedItemsCount > 0
          ? _c(
              "span",
              {
                key: `count-${_vm.selectedItemsCount}`,
                staticClass:
                  "options-count align-self-center ml-2 animate__animated animate__bounceIn",
              },
              [_vm._v("\n      " + _vm._s(_vm.selectedItemsCount) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-angle-down align-self-center ml-2" }),
      ]),
      _vm._v(" "),
      _c(
        "b-form-checkbox-group",
        {
          staticClass: "option-check-group row m-2",
          attrs: { stacked: "" },
          model: {
            value: _vm.selectedItems,
            callback: function ($$v) {
              _vm.selectedItems = $$v
            },
            expression: "selectedItems",
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "b-form-checkbox",
            {
              key: `option-${index}`,
              staticClass: "col-4 my-2",
              attrs: { value: option[_vm.optionKey] },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  option[_vm.optionImage]
                    ? _c("b-avatar", {
                        staticClass: "align-self-center mr-2",
                        attrs: {
                          src: option[_vm.optionImage],
                          variant: "light",
                          text: _vm.getNameInitials(option[_vm.optionName]),
                          size: "25",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "align-self-center" }, [
                    _vm._v(_vm._s(option[_vm.optionName])),
                  ]),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }