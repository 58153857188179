<template>
  <div class="audit-log">
    <div class="header d-flex align-self-center mb-3">
      <h1 class="p-0 mb-0 align-self-center mr-3">Audit Log</h1>
      <team-selector :loading="loadingTeams" :teams="teams" v-model="activeTeam"></team-selector>
    </div>
    <audit-log-card-list
      :team="activeTeam"
      :loading="loadingAuditLogFilters || loadingTeamUsers"
    ></audit-log-card-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TeamSelector from '@/components/Control/TeamSelector.vue'
import AuditLogCardList from '@/components/Dashboard/AuditLogCardList.vue'
import { managementMixin } from '@/mixins/managementMixin'
import { helperMixin } from '@/mixins/helperMixin'
export default {
  components: {
    TeamSelector,
    AuditLogCardList
  },
  mixins: [managementMixin, helperMixin],
  data() {
    return {
      loading: false,
      loadingTeams: true,
      loadingAuditLogFilters: true,
      loadingTeamUsers: true,
      teams: [],
      activeTeam: this.createNewTeam(),
      activeTeamIndex: 0,
      switchView: false
    }
  },
  methods: {
    ...mapActions(['fetchTeams', 'fetchAuditLogFilters', 'fetchAllUsers']),
    createNewTeam() {
      return {
        id: null,
        name: '',
        avatar: ''
      }
    },
    async getTeams() {
      let self = this
      if (self.hasLeaderRole) {
        self.activeTeam.id = self.currentUser.teamId
        self.activeTeam.name = self.currentUser.team
        self.activeTeam.avatar = self.currentUser.teamImg
        self.teams.push(self.activeTeam)
        self.loadingTeams = false
        return
      }

      await self.fetchTeams()
      self.teams = self.allTeams
      self.activeTeam = await self.teams[self.activeTeamIndex]
      self.loadingTeams = false
    },
    async getAuditLogFilters() {
      let self = this
      await self.fetchAuditLogFilters()
      self.loadingAuditLogFilters = false
    },
    async getAllTeamUsers() {
      let self = this
      self.loadingTeamUsers = true
      await self.fetchAllUsers()
      self.loadingTeamUsers = false
    }
  },
  computed: {
    ...mapGetters(['allTeams', 'allUsers'])
  },
  async created() {
    await this.getTeams()
    await this.getAuditLogFilters()
  },
  watch: {
    activeTeam: {
      handler(value) {
        if (value.id) {
          this.getAllTeamUsers()
        }
      },
      deep: true
    }
  }
}
</script>
