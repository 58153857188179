<template>
  <b-dropdown
    class="multi-user-selection-filter"
    variant="none"
    toggle-class="options-toggle d-flex align-self-center p-0"
    menu-class="options-menu a-shadow-sm"
    no-caret
  >
    <template slot="button-content">
      <span class="align-self-center">{{ label }}</span>
      <span
        v-if="selectedItemsCount > 0"
        :key="`count-${selectedItemsCount}`"
        class="options-count align-self-center ml-2 animate__animated animate__bounceIn"
      >
        {{ selectedItemsCount }}
      </span>
      <i class="fa fa-angle-down align-self-center ml-2"></i>
    </template>
    <b-form-checkbox-group v-model="selectedItems" stacked class="option-check-group row m-2">
      <b-form-checkbox
        v-for="(option, index) in options"
        :key="`option-${index}`"
        :value="option[optionKey]"
        class="col-4 my-2"
      >
        <div class="d-flex">
          <b-avatar
            v-if="option[optionImage]"
            :src="option[optionImage]"
            variant="light"
            :text="getNameInitials(option[optionName])"
            size="25"
            class="align-self-center mr-2"
          ></b-avatar>
          <span class="align-self-center">{{ option[optionName] }}</span>
        </div>
      </b-form-checkbox>
    </b-form-checkbox-group>
  </b-dropdown>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    optionName: {
      type: String,
      default: 'name'
    },
    optionImage: {
      type: String,
      default: 'image'
    }
  },
  mixins: [helperMixin],
  computed: {
    selectedItems: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    selectedItemsCount() {
      return this.selectedItems.length
    }
  }
}
</script>
