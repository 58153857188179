var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "audit-log-card-list" }, [
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "w-100 d-flex flex-column flex-lg-row px-4 py-2" },
          [
            _c("p", {
              staticClass:
                "mb-0 w-4 w-sm-100 align-self-center font-bold text-center",
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 w-8 w-sm-100 align-self-center font-bold text-center",
              },
              [
                _c("multi-user-selection-filter", {
                  attrs: {
                    label: "Team Member",
                    options: _vm.userOptions,
                    optionImage: "avatar",
                  },
                  model: {
                    value: _vm.selectedUsers,
                    callback: function ($$v) {
                      _vm.selectedUsers = $$v
                    },
                    expression: "selectedUsers",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 w-6 w-sm-100 align-self-center font-bold text-center",
              },
              [
                _c("multi-selection-filter", {
                  attrs: { label: "Week", options: _vm.weekOptions },
                  model: {
                    value: _vm.selectedWeeks,
                    callback: function ($$v) {
                      _vm.selectedWeeks = $$v
                    },
                    expression: "selectedWeeks",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 w-6 w-sm-100 align-self-center font-bold text-center",
              },
              [
                _c("multi-selection-filter", {
                  attrs: { label: "Day", options: _vm.dayOptions },
                  model: {
                    value: _vm.selectedDays,
                    callback: function ($$v) {
                      _vm.selectedDays = $$v
                    },
                    expression: "selectedDays",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mb-0 w-6 w-sm-100 align-self-center font-bold text-center",
              },
              [
                _c("multi-selection-filter", {
                  attrs: { label: "Result", options: _vm.claimResultOptions },
                  model: {
                    value: _vm.selectedClaimResults,
                    callback: function ($$v) {
                      _vm.selectedClaimResults = $$v
                    },
                    expression: "selectedClaimResults",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.auditLogFilters.fields, function (field, index) {
              return _c(
                "div",
                {
                  key: `field-${index}`,
                  staticClass:
                    "mb-0 w-8 w-sm-100 align-self-center font-bold text-center mx-auto",
                },
                [
                  field.name.toLowerCase() === "policy type"
                    ? _c("multi-selection-filter", {
                        staticClass: "mx-auto",
                        attrs: {
                          label: "Policy Type",
                          options: _vm.policyTypeOptions,
                        },
                        model: {
                          value: _vm.selectedPolicies,
                          callback: function ($$v) {
                            _vm.selectedPolicies = $$v
                          },
                          expression: "selectedPolicies",
                        },
                      })
                    : _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: field.name,
                                placement: "top-center",
                                classes: ["light"],
                                delay: {
                                  show: 500,
                                  hide: 300,
                                },
                                offset: "5",
                              },
                              expression:
                                "{\n          content: field.name,\n          placement: 'top-center',\n          classes: ['light'],\n          delay: {\n            show: 500,\n            hide: 300\n          },\n          offset: '5'\n        }",
                            },
                          ],
                          staticClass: "mb-0 font-bold claim-field mx-auto",
                        },
                        [_vm._v("\n        " + _vm._s(field.name) + "\n      ")]
                      ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mb-0 w-5 w-sm-100 align-self-center text-center",
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-shadow",
                    attrs: {
                      size: "xs",
                      variant: "danger",
                      disabled: _vm.disabledResetFilter,
                    },
                    on: { click: _vm.resetFilters },
                  },
                  [_vm._v("\n        Reset\n      ")]
                ),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loading && _vm.auditLogData && _vm.auditLogData.length === 0
      ? _c(
          "div",
          { staticClass: "d-flex mt-4" },
          [
            _c("running-loader", {
              staticClass: "mx-auto",
              attrs: { loading: "", loadingText: "Loading data..." },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "transition-group",
              {
                attrs: {
                  name: "card",
                  tag: "div",
                  "enter-active-class": "animate__animated animate__fadeInDown",
                },
              },
              _vm._l(_vm.displayedAuditLog, function (auditLog, index) {
                return _c("audit-log-card", {
                  key: auditLog.claimId,
                  staticClass: "mb-2",
                  attrs: { auditLog: auditLog },
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.displayedAuditLog && _vm.displayedAuditLog.length > 0
              ? _c(
                  "div",
                  { staticClass: "d-flex mt-4" },
                  [
                    _c("b-pagination", {
                      staticClass: "mx-auto",
                      attrs: {
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("speech-bubble", {
                      staticClass: "mx-auto mt-5",
                      attrs: {
                        show:
                          !_vm.loadingAuditLogData &&
                          _vm.displayedAuditLog.length === 0,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [_vm._v("No audit log found")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("speech-bubble", {
                      staticClass: "mx-auto mt-5",
                      attrs: { show: _vm.loadingAuditLogData },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [_vm._v("Loading audit log...")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }