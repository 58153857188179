var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audit-log-card" },
    [
      _c(
        "b-card",
        _vm._g(
          {
            class: { "audit-log-card-expand": !_vm.auditLogData.claimResult },
            attrs: { "body-class": "d-flex px-4 py-3 card-body" },
          },
          !_vm.auditLogData.claimResult ? { click: _vm.viewDetails } : {}
        ),
        [
          _c(
            "div",
            {
              staticClass: "w-4 align-self-center text-center claim-timestamp",
            },
            [
              _c("p", { staticClass: "m-0 font-bold" }, [
                _vm._v(_vm._s(_vm.claimSubmitTimestampDate)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "m-0" }, [
                _vm._v(_vm._s(_vm.claimSubmitTimestampTime)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-8 align-self-center text-center" },
            [
              _c("b-avatar", {
                attrs: {
                  src: _vm.userAvatar,
                  text: _vm.getNameInitials(_vm.userName),
                  size: "30",
                  variant: "light",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(_vm.userName)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-6 align-self-center text-center" },
            [
              _c("b-badge", { attrs: { variant: "outline-info" } }, [
                _vm._v(_vm._s(_vm.weekName)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-6 align-self-center text-center" },
            [
              _c("b-badge", { attrs: { variant: "outline-new" } }, [
                _vm._v(_vm._s(_vm.dayName)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-6 align-self-center text-center" },
            [
              _c(
                "b-badge",
                { attrs: { pill: "", variant: _vm.resultVariant } },
                [_vm._v(_vm._s(_vm.result))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.claim, function (c, index) {
            return _c(
              "div",
              {
                key: `answer-${index}`,
                staticClass: "w-8 align-self-center text-center mx-auto",
              },
              [
                c.result !== null
                  ? _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "m-0",
                          class: { "text-danger font-semi-bold": !c.result },
                        },
                        [
                          !isNaN(Number(c.input))
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatPayment")(Number(c.input))
                                  )
                                ),
                              ])
                            : _c("span", [_vm._v(_vm._s(c.input))]),
                          _vm._v(" "),
                          !c.result
                            ? _c("i", {
                                staticClass:
                                  "fa fa-times-circle text-danger ml-1",
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.detailsViewed && c.result === false && c.original
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "mt-1 m-0 animate__animated animate__fadeIn",
                            },
                            [
                              !isNaN(c.original)
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatPayment")(c.original)
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v(_vm._s(c.original))]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa fa-check-circle text-success ml-1",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-5 align-self-center text-center action" },
            [
              _vm.auditLogData.claimResult
                ? _c("i", {
                    staticClass:
                      "fa fa-caret-down align-self-center text-light",
                  })
                : _c("i", {
                    key: `view-details-${_vm.detailsViewed}`,
                    staticClass:
                      "fa view-details align-self-center animate__animated animate__flipInX",
                    class: _vm.detailsViewed
                      ? "fa-caret-up text-info"
                      : "fa-caret-down text-muted",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.viewDetails.apply(null, arguments)
                      },
                    },
                  }),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }